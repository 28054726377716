<template>
  <div class="container-fluid about-us">
    <div class="row">
      <div class="col-12">
        <h2 class="title-primary">ITFA</h2>
        <h3 class="text-center mb-3">{{$t('itfa_abr')}}</h3>
        <div v-html="$t('about_us')"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted () {
    document.title = `${this.$t('aboutUs')} - ITFA`
  }
}
</script>
